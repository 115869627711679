<template>
    <div class="center-content">
        <section class="w-full my-20">
            <div class="container px-4 mx-auto">
                <div class="flex flex-wrap items-center">
                    <div class="w-full px-4 ml-auto mr-auto sm:w-8/12 md:w-7/12 lg:w-9/12 flex">
                        <div
                            class="relative flex flex-col w-full min-w-0 mb-6 break-words bg-white shadow-lg rounded-lg lg:rounded-tr-none lg:rounded-br-none lg:w-6/12">
                            <div class="flex-auto px-4 py-10 lg:px-5">
                                <div class="w-full">
                                    <h1 class="mb-4 text-3xl font-bold text-center">โปรดเข้าสู่ระบบ</h1>
                                    <p class="mb-4 text-sm text-gray-600 text-center">
                                        กรุณากรอกข้อมูลเพื่อเข้าสู่ระบบ
                                    </p>
                                    <div class="flex-auto px-4 py-2 pt-0 lg:px-10">
                                        <UForm class="w-full" @submit="onSubmit" :state="state" :schema="schema"
                                            method="POST">
                                            <UAlert v-if="formErrors.length" class="mb-5 border border-red-300 bg-red-100"
                                                title="เกิดข้อผิดพลาด !">
                                                <template #title>
                                                    <span class="font-bold text-red-500">
                                                        เกิดข้อผิดพลาด !
                                                    </span>
                                                </template>
                                                <template #description>
                                                    <p v-for="error in formErrors" :key="error">{{ error }}</p>
                                                </template>
                                            </UAlert>
                                            <UFormGroup class="mb-3" label="อีเมล์" name="email">
                                                <UInput v-model="state.email" name="email" label="อีเมล์" size="lg"
                                                    placeholder="อีเมล์" type="text" required class="mb-3" />
                                            </UFormGroup>
                                            <UFormGroup class="mb-3" label="รหัสผ่าน" name="password">
                                                <UInput v-model="state.password" size="lg" name="password" label="รหัสผ่าน"
                                                    placeholder="รหัสผ่าน" type="password" required class="mb-3" />
                                            </UFormGroup>
                                            <UButton type="submit" color="primary" size="lg" class="my-2">
                                                เข้าสู่ระบบ
                                            </UButton>
                                        </UForm>
                                        <span class="text-sm text-gray-600">
                                            หากไม่สามารถเข้าสู่ระบบได้ โปรดติดต่อผู้ดูแลระบบ หรือ <NuxtLink
                                                href="/auth/forgetPassword" class="text-gray-600  hover:underline">
                                                ลืมรหัสผ่าน?
                                            </NuxtLink>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="hidden shadow-lg lg:w-6/12 lg:block bg-gradient-to-r from-[#214F5F] to-[#326C7A] mb-6 rounded-tl-none rounded-tb-none rounded-br-lg rounded-tr-lg py-10">
                            <div class="flex flex-col justify-center mb-6 items-center">
                                <NuxtImg height="150" alt="cmru logo" src="/images/cmrulogo.png" class="mb-4" />
                                <h2 class="mx-4 font-bold text-center text-2xl text-white">
                                    ฐานข้อมูลทรัพยากรท้องถิ่น
                                </h2>
                                <h3 class="mt-5 mx-4 text-center text-lg text-white">
                                    มหาวิทยาลัยราชภัฏเชียงใหม่ วิทยาเขตแม่ฮ่องสอน
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script lang="ts" setup>
/* __placeholder__ */
import type { FormSubmitEvent } from '@nuxt/ui/dist/runtime/types';
import { type InferType, object, string } from 'yup';
import { useLoginMutation } from '~/graphql/types';

const { mutate: loginMutation, loading: loginLoading, error: loginError } = useLoginMutation();
const { getToken, onLogin } = useApollo()

const state = reactive({
    email: '',
    password: '',
});

watch(loginError, (error) => {
    if (error) {
        formErrors.value = error.graphQLErrors.map((err) => err.message);
    }
});

definePageMeta({
    layout: 'auth',
})

onMounted(async () => {
    const token = await getToken()
    if (token) {
        await navigateTo("/admin");
    }
});

const schema = object({
    email: string().email("กรุณากรอกรูปแบบอีเมล์ให้ถูกต้อง").required("กรุณากรอกอีเมล"),
    password: string().required("กรุณากรอกรหัสผ่าน"),
})


type Schema = InferType<typeof schema>;

const formErrors = ref<string[]>([]);

async function onSubmit(event: FormSubmitEvent<Schema>) {
    const result = await loginMutation({
        email: state.email,
        password: state.password
    }, {
        fetchPolicy: "network-only",
    });

    if (result?.data?.login) {
        onLogin(result.data.login.access_token);
        navigateTo("/admin");
    }
}
</script>   

<style scoped>
.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    position: relative;
}
</style>